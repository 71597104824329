

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/vice-cache-etapes-suivre-recours-vendeur-avocat-quebec.jpeg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost46 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Que faire si vous découvrez un vice caché lors de l’achat ou la vente d’une propriété? - Soumissions Avocat"
        description="Les transactions immobilières sont des opérations très délicates qui mettent en jeu d’importantes sommes d’argent. Le transfert d’une propriété n’est pas traité comme une procédure banale, car elle peut mettre toutes les parties dans de beaux draps lorsqu’elle tourne au vinaigre. C’est notamment le cas des ventes de maisons ou LIRE PLUS"
        image={LeadImage}>
        <h1>Que faire si vous découvrez un vice caché lors de l’achat ou la vente d’une propriété?</h1>

					
					
						<p>Les transactions immobilières sont des opérations très délicates qui mettent en jeu d’importantes sommes d’argent. Le transfert d’une propriété n’est pas traité comme une procédure banale, car elle peut mettre toutes les parties dans de beaux draps lorsqu’elle tourne au vinaigre. C’est notamment le cas des ventes de maisons ou d’immeubles qui impliquent la découverte d’un vice caché!</p>
<p><StaticImage alt="vice-cache-etapes-suivre-recours-vendeur-avocat-quebec" src="../images/vice-cache-etapes-suivre-recours-vendeur-avocat-quebec.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>De telles malfaçons peuvent ruiner la valeur d’une propriété immobilière et forcer l’acheteur de celle-ci à procéder à d’importants travaux qui lui couteront un bras et même une jambe! Pour éviter une injustice aussi flagrante, la loi prévoit cependant des mécanismes efficaces pour remédier à la situation et retrouver un équilibre de justice. Voici donc ce que vous devriez faire avec l’aide d’un avocat à la découverte d’un vice caché!</p>
<h2>Êtes-vous bien en présence d’un vice caché?</h2>
<p>La première étape et non la moindre est d’établir la présence d’un vice et vérifier si les critères du trouble qui vous afflige respectent ceux prévus par la loi. Bien qu’un défaut de fabrication puisse être dispendieux à corriger et pour le moins irritant, s’il n’entre pas dans la catégorie des vices cachés, il n’y a rien qu’un avocat pourra faire pour vous. Pour se qualifier au sens de la loi, le vice caché doit avoir les caractéristiques suivantes :</p>
<ul>
<li><strong>Vice inconnu au moment de la vente</strong> : Si l’acheteur sait qu’une partie de la maison est affligée d’une malfaçon, il ne pourra pas soulever le vice caché pour être dédommagé.</li>
<li><strong>Vice d’une certaine gravité</strong> : La gravité d’un vice se constate dans les faits en prenant en compte le dommage causé, le prix à payer pour faire les réparations et toute autre information pertinente.</li>
<li><strong>Vice doit précéder la vente </strong>: Le vice doit avoir été la faute d’un propriétaire antérieur, sans quoi l’acheteur sera le seul responsable des travaux.</li>
<li><strong>Vice doit être caché </strong>: En plus d’être inconnu, le vice doit également être caché, c’est-à-dire, dissimulé dans la maison.</li>
<li><strong>Vice doit exister </strong>: Il doit être possible pour l’acheteur de prouver l’existence du vice, mais également les dommages subis. Il doit surtout correspondre à la définition de vice caché, soit : une malfaçon, une défectuosité, une violation des règles de l’art, etc.</li>
</ul>
<p><strong>La garantie de qualité et le droit de l’acheteur à une maison adéquate.</strong> Le droit prévoit que les acheteurs ont le droit d’acheter maison de qualité et qu’une garantie est même prévue pour compenser les acheteurs dont le droit est bafoué.</p>
<h2>Quelle est la marche à suivre après avoir constaté le vice caché?</h2>
<p>Après avoir acheté votre maison, si vous constatez une malfaçon que vous croyez être un vice caché, il est important de ne pas tarder à remédier à la situation, et ce, à tous les égards. Si le vice compromet l’intégrité de votre maison, il est essentiel de faire effectuer les travaux de réparation pour éviter de commettre une faute contributive qui pourrait vous faire perdre votre recours. Au niveau de la marche à suivre légale, voici les étapes à entreprendre contre votre vendeur pour être dédommagé!</p>
<ul>
<li><strong>Aviser le vendeur par écrit de l’existence du vice. </strong>La loi prévoit que l’acheteur se doit de prévenir le vendeur de l’existence du vice à l’intérieur d’un délai raisonnable. Ce critère s’apprécie évidemment en fonction des circonstances particulières et du type de vice découvert.</li>
</ul>
<p><StaticImage alt="prouver-existence-gravite-vice-cache-anterieur-vente-avocat" src="../images/prouver-existence-gravite-vice-cache-anterieur-vente-avocat.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Il est également important de n’effectuer les travaux que si ceux-ci sont urgents si vous ne voulez pas payer pour la réparation. Il vous faut laisser la chance au vendeur de constater le vice caché. Après ce constat du vice caché, le vendeur réagira soit en offrant de payer pour ceux-ci ou en alléguant son absence de responsabilité.</p>
<p>OU</p>
<ul>
<li><strong>Mettre le vendeur en demeure :</strong> Si vous êtes convaincu de la réaction négative du vendeur, vous pouvez toujours le mettre en demeure immédiatement en prenant soin de préciser la nature du vice caché, vos demandes à son égard en termes de travaux à effectuer ainsi que le délai que vous lui donnez pour répondre. Les recours pour vice caché sont parmi les rares poursuites qui exigent l’envoi d’une mise en demeure obligatoire avant de se présenter en cour.</li>
<li><strong>Viser un règlement à l’amiable : </strong>Cette étape peut avoir lieu avant ou après la mise ne demeure. Lors des poursuites civiles au Québec, il n’est jamais trop tard pour abandonner la poursuite et informer le juge qu’un règlement à l’amiable s’est produit. Ainsi, vous pouvez éviter l’aventure du système judiciaire en vous entendant avec le vendeur pour corriger le vice en prenant soin de mettre cet accord par écrit.</li>
<li><strong>Poursuivre le vendeur :</strong> L’étape ultime utilisée en dernier ressort est celle d’engager un avocat pour poursuivre le vendeur. L’avocat s’assurera que la mise en demeure est proprement rédigée et qu’elle contient tous les éléments nécessaires pour entamer la poursuite. Il tentera ensuite de convaincre le juge que le trouble correspond aux critères mentionnés ci-haut!</li>
</ul>
<h2>Quels sont les recours de l’acheteur de la maison?</h2>
<p>Advenant une reconnaissance du juge quant à l’existence d’un vice caché, quelles sont les modalités d’indemnisation auxquelles vous avez droit? de quelle façon serez-vous dédommagés pour le vice subi. La réponse ne se limite pas aux dommages octroyés, car cela n’est pas toujours suffisant pour réparer le préjudice. Un juge décidera donc parmi les options suivantes :</p>
<ul>
<li><strong>Remboursement d’une partie du prix de vente :</strong> En analysant le vice, les réparations à effectuer et la valeur de la maison, le juge regardera le prix de vente et l’effet que le vice aurait eu sur celui-ci avait-il été connu de l’acheteur. Il pourrait donc décider de demander un remboursement du prix de vente à l’acheteur.</li>
<li><strong>Remboursement des travaux de réparation du vice :</strong> Lorsque la nature du vice s’y prête et que le simple remboursement des travaux est suffisant pour que justice soit faite, un juge pourra ordonner pareille réparation.</li>
<li><strong>Octroi de dommages-intérêts :</strong> Advenant qu’un juge constate une intention manifeste, voire malicieuse d’un vendeur de cacher le vice à l’acheteur, il lui sera possible d’ordonner le paiement de dommages-intérêts, en plus du remboursement des travaux.</li>
<li><strong>Restitution des prestations :</strong> Le principe de restitution des prestations s’applique lorsqu’une erreur, simple ou dolosive, s’insère dans le contrat conclu. Le vice caché a pour effet d’affecter la qualité du consentement et de vicier un contrat. Cela peut donc donner lieu à l’annulation de la vente de la maison lorsque le vice est important, forçant ainsi le vendeur à récupérer son bien et à remettre le montant versé.</li>
</ul>
<h2>Le vendeur peut-il soulever une défense contre un vice caché?</h2>
<p>Il va de soi que le vendeur accusé d’avoir menti à l’acheteur pour lui vendre une maison en piteux état ne se laissera pas faire sans répliquer. Il est fort possible que celui-ci ignorait le vice et que la malfaçon date d’avant son propre séjour en tant que propriétaire de la maison. Pour ces raisons fort raisonnables, voici les principales défenses qu’un vendeur peut invoquer pour s’exonérer.</p>
<p><StaticImage alt="services-avocats-vente-maison-vice-cache-annulation" src="../images/services-avocats-vente-maison-vice-cache-annulation.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<ul>
<li><strong>Contester les attributs du vice :</strong> Un vendeur peut très bien alléguer que le vice n’était pas caché et que l’acheteur était très bien au fait des réparations à effectuer au moment de l’achat. Il faudra regarder les documents de la transaction et se fier aux témoignages des parties.</li>
<li><strong>Prétendre que le vice résulte de l’usure normale :</strong> Tout problème de bris dans une maison n’est pas une cause de vice caché. Il existe un principe légal d’usure normale des lieux. Il est donc possible qu’un tuyau de plomberie éclate sans qu’on soit en présence d’un vice caché.</li>
<li><strong>Alléguer une faute de l’acheteur :</strong> Le vendeur peut aussi se défendre en alléguant que c’est l’acheteur qui a causé les bris reportés.</li>
<li><strong>Soulever une clause d’exclusion de responsabilité :</strong> Si la maison a été vendue sans garantie légale ou encore « aux risques et périls » de l’acheteur, il sera possible pour le vendeur de s’exonérer de sa responsabilité.</li>
</ul>
<h2>La vente sans garantie légale et la vente aux risques et périls de l’acheteur</h2>
<p>Lorsqu’une maison datant du siècle précédent est mise en vente et qu’elle a clairement déjà vu de meilleurs jours, il est évident qu’un vendeur s’expose à un risque, soit celui de la vendre en présence d’un vice caché. Il existe cependant des façons dont un vendeur peut vendre une maison en limitant sa responsabilité face à de tels vices. Ces deux mécanismes sont ceux qui sont utilisés dans la plupart des cas!</p>
<p><strong>Achat sans garantie légale :</strong> La loi québécoise prévoit automatiquement que toutes les ventes immobilières sont couvertes par une garantie légale de qualité qui vise à protéger les acheteurs contre des vices cachés. Il est toutefois possible d’exclure cette garantie légale par contrat lors de la vente en stipulant que le vendeur ne garantit pas la qualité de la maison et qu’il n’assume donc pas la présence d’un vice caché. Cependant, si le vendeur connait un vice, un ne peut pas exclure sa responsabilité et il a le devoir de le divulguer, sans quoi il s’agira de manœuvres illégales.</p>
<p><strong>Achat « aux risques et périls de l’acheteur » :</strong> Cette mention est souvent ajoutée aux ventes sans garantie légale et vise à dégager encore plus le vendeur de sa responsabilité vis-à-vis d’éventuels vices découverts. Notez toutefois que ces clauses ne sont pas absolues et qu’elles ne protègent pas contre toutes les poursuites.</p>
<p><strong>Il va également de soi que l’avantage d’acheter une maison avec de telles mentions est de diminuer le prix d’achat. C’est d’ailleurs la raison pour laquelle de telles clauses sont légales : l’acheteur comme le vendeur en retire des avantages. </strong></p>
<h2>Qu’est-ce qu’un avocat peut faire pour vous lors de la découverte d’un vice caché?</h2>
<p>Vous vous demandez ce qu’un avocat peut faire pour vous en matière de vice caché? Il traitera votre dossier comme une poursuite civile pour que vous n’ayez pas à payer le moindre sou pour réparer la faute d’une autre personne. Notamment, il fera les démarches suivantes :</p>
<ul>
<li><strong>Constater la présence d’un vice </strong></li>
<li><strong>Préparer la mise en demeure </strong></li>
<li><strong>Négocier avec le vendeur </strong></li>
<li><strong>Intenter une poursuite civile </strong></li>
</ul>
<h2>Soumissions Avocat peut vous mettre en contact avec les experts en matière de vices cachés!</h2>
<p>Une poursuite pour vice caché demande des efforts, du temps et surtout de la patience. Le système de justice va à son propre rythme, alors il faut savoir comment le naviguer pour obtenir gain de cause. C’est justement ce que l’expertise de nos avocats partenaires vous apporte! En faisant affaire avec eux, vous éviterez de payer pour un trouble que vous auriez dû connaitre avant l’achat de votre maison!</p>
<p><strong>Il ne vous reste qu’une seule chose à faire pour trouver votre avocat, soit remplir le formulaire! Vous recevrez une offre de service d’un avocat compétent dans les plus brefs délais!</strong></p>
<p><strong>À quoi bon attendre quand nos services sont gratuits et ne vous engagent à rien!</strong></p>
    </SeoPage>
)
export default BlogPost46
  